import styled from "styled-components"

import { _Blockquote } from "../../shared/blockquote.styles"
import { _h2 } from "../../shared/headline.styles"

export const _list = styled.ul`
    list-style: none;
    margin: 0 0 1.5em;
    padding: 0;
`

export const _item = styled.li`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
    display: flex;
    margin-bottom: 0.7em;

    img {
        margin-right: 1em;
        width: 20px;
        height: 24px;
    }
`

export const _itemContent = styled.div`
    p {
        margin: 0;
        width: 100%;
        display: block;
    }
`

export const _title = styled(_h2)`
    font-size: 36px;

    @media only screen and (min-width: 576px) {
        font-size: 36px;
    }

    @media only screen and (min-width: 768px) {
       font-size: 36px;
    }

    @media only screen and (min-width: 992px) {
        font-size: 42px;
    }

    p {
        color: #000;
        margin: 0;
    }
`

export const _caseTitle = styled.p`
    font-family: Poppins;
    font-size: 18px;
    color: #5d5d70;
    text-transform: uppercase;
    margin-bottom: 8px;
`

export const _index = styled.span`
    font-family: Poppins;
    font-size: 56px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: rgba(255, 129, 85, 0.45);
    position: absolute;
    left: 0;
    top: 0;
    margin: -40px -10px;

    @media only screen and (min-width: 992px) {
        margin: -40px -25px;
    }
`

export const _wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0;
`

export const _nextSlideButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin-left: 30px;
  padding: 0;
  margin-right: 17px;
  margin-top: -85px;
  position: relative;

  img {
    width: 40px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 0;
    margin-right: 0;
  }
`

export const _itemPosition = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    @media only screen and (min-width: 576px) {
        margin: 17px 0 0;
    }

    @media only screen and (min-width: 768px) {
        margin: 17px 0 0;
        justify-content: flex-end;
    }

    @media only screen and (min-width: 992px) {
        margin: 17px 5px 0;
        justify-content: flex-end;
    }

    @media only screen and (min-width: 1200px) {
        margin: 17px 30px 0;
        justify-content: flex-end;

    }
`

export const _indexPosition = styled.span`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    text-align: center;
    color: #5a5a5a;

    &.current {
        color: #5a1e5f;
    }
`

export const _separator = styled.span`
    margin: 5px 9px;
    height: 1px;
    background-color: #646c7a;
    width: 60px;
    display: inline-block;
`

export const CaseSectionSliderItem = styled.div`

`

export const ShadowedImage = styled.div`
    position: relative;
    padding: 5px;

    img {
        border-radius: 22px;
        box-shadow: 0 2px 7px 0 rgba(135, 134, 234, 0.21);
        background-color: rgba(255, 255, 255, 0.66);
        width: 100%;
        max-width: 590px;
    }

    &:before, &:after {
        width: 85px;
        height: 67px;
        -webkit-filter: blur(28px);
        filter: blur(28px);
        opacity: 0.64;
        position: absolute;
        z-index: -1;
        content: '';

        @media only screen and (min-width: 576px) {
            width: 158px;
            height: 180px;
        }
    }

    &:before {
        background-color: rgba(255, 129, 85, 0.64);
        bottom: -10px;
        left: 100px;
    }

    &:after {
        top: -10px;
        right: 100px;
        -webkit-filter: blur(28px);
        filter: blur(28px);
        background-color: #5a1e5f;
    }
`

export const SliderContainer = styled.div`
    .slick-list {
        margin: -60px 0;
        padding-top: 80px;
        padding-bottom: 80px;
    
        @media only screen and (min-width: 768px) {
            margin: 0;
            padding-top: 80px;
            padding-bottom: 80px;
        }
    
        @media only screen and (min-width: 992px) {
            margin: 0;
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
`

export const _contentContainer = styled.div`
    @media only screen and (min-width: 768px) {
        padding: 0 25px;
    }
`

export const _caseContent = styled(_Blockquote)`
    strong {
        color: #000;
    }
`