import * as React from "react"
import styled from "styled-components"

import { _h2, _h3 } from "../../../shared/headline.styles"
import { _Blockquote } from "../../../shared/blockquote.styles"
import { _customMarginRow } from "../../../shared/row.styles"
import { _buttonBlock } from "../../../shared/buttons"
import closeSvg from "../../../../images/close.svg"
import { HubspotFormContainer } from "./domainInsightsForm/Form"

import Modal from "react-modal"
Modal.setAppElement(`#___gatsby`)

const _closeModalButton = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    margin: 1em;
    border: none;

    img {
        cursor: pointer;
    }
`

interface Styles {
    content?: React.CSSProperties
    overlay?: React.CSSProperties
}

const modalStyles: Styles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.2)"
    },
    content: {
        position: "absolute",
        top: "50%",
        left: "0",
        right: "0",
        bottom: "unset",
        maxWidth: "540px",
        margin: "auto",
        padding: "5em",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1)",
        border: 0,
        backgroundColor: "white",
        maxHeight: "fit-content",
        transform: "translate(0, -50%)"
    }
}

interface Props {
    isOpen: boolean
    closeModal: void
}

export const DomainInsightsNewsletterModal: React.FC<Props> = ({ isOpen, closeModal }) => {
    const modalCloseTimeout = 300

    React.useEffect(() => {
        const script = document.createElement("script")
        script.src = `//js.hsforms.net/forms/shell.js`
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <Modal
            className="Modal"
            isOpen={isOpen}
            onRequestClose={() => {
                closeModal()
            }}
            style={modalStyles}
            contentLabel="Modal"
            closeTimeoutMS={modalCloseTimeout}>
            <_closeModalButton
                onClick={() => {
                    closeModal()
                }}>
                <img src={closeSvg} />
            </_closeModalButton>

            <HubspotFormContainer />
        </Modal>
    )
}
