import * as React from "react"
import styled from "styled-components"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h2, _h3, _description } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _customMarginRow } from "../shared/row.styles"
import { _button } from "../shared/buttons"
import { DebriefNewsletterModal } from "./shared/modals/DebriefNewsletterModal"
import { DomainInsightsNewsletterModal } from "./shared/modals/DomainInsightsNewsletterModal"
import { RangerNewsletterModal } from "./shared/modals/RangerNewsletterModal"

import { _customAlignCol } from "../shared"
import { _externalLinkButton } from "../shared/buttons"

const _styledExternalLinkButton = styled(_externalLinkButton)`
    padding-left: 10px;
    padding-right: 10px;

    @media only screen and (min-width: 576px) {
        padding-left: 39px;
        padding-right: 39px;
    }
`

interface DebriefHelpProps {
    label: string
    link?: string
    caseTitle?: string
}

export const CtaButton: React.FC<DebriefHelpProps> = ({ label, link, caseTitle }) => {
    const appState = React.useContext(AppStateContext)
    const [modalOpen, setModalOpen] = React.useState(false)

    const closeModal = () => {
        setModalOpen(false)
    }

    const openModal = () => {
        setModalOpen(true)
    }

    if (link) {
        return (
            <_styledExternalLinkButton marginTop={appState.isMobile ? 0 : 2} href={link}>
                {label}
            </_styledExternalLinkButton>
        )
    }

    return (
        <>
            {caseTitle === "Debrief" && <DebriefNewsletterModal isOpen={modalOpen} closeModal={closeModal} />}
            {caseTitle === "Ranger" && <RangerNewsletterModal isOpen={modalOpen} closeModal={closeModal} />}
            {caseTitle === "Domain insights" && (
                <DomainInsightsNewsletterModal isOpen={modalOpen} closeModal={closeModal} />
            )}

            <_button marginTop={appState.isMobile ? 0 : 2} onClick={openModal}>
                {label}
            </_button>
        </>
    )
}
