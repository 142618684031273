import styled from "styled-components"

import { _h3, _h2 } from "../shared/headline.styles"

export const _title = styled(_h2)`
  font-size: 45px;
  
  p {
    margin: 0;
    color: var(--dark);
  }
`

export const _cardWrapper = styled.div`
    margin: 0;
    border-radius: 22px;
    box-shadow: 0 2px 7px -1px rgb(135 134 234 / 20%);
    background-color: rgba(255, 255, 255, 0.66);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 23px 31px;
    position: relative;
    overflow: hidden;
    max-width: 366px;
    width: 100%;

    &:before {
        position: absolute;
        z-index: -1;
        top: 70px;
        left: 48px;
        content: "";
        width: 207px;
        height: 258px;
        filter: blur(28px);
        opacity: 0.64;
        background-color: rgba(255, 129, 85, 0.64);
    }

    &:nth-child(2n) {
        &:before {
            background-color: #4340de;
        }
    }
`

export const _cardImage = styled.img`
    max-height: 75px;
    margin-bottom: 19px;
`

export const _cardTitle = styled(_h3)`
    margin-bottom: 15px;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 0;

    @media only screen and (min-width: 992px) {
        font-size: 18px;
    }
`

export const _cardContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    position: relative;
    padding-bottom: 2em;

    @media only screen and (min-width: 768px) {
        padding-bottom: 2em;
        padding-top: 2em;
    }

    @media only screen and (min-width: 1400px) {
        width: 1400px;
        margin-left: calc((100% - 1400px) / 2);
    }
`

export const _centered = styled.div`
    text-align: center;
`
