import styled from 'styled-components'

export const _talentIdImage = styled.img`
  max-width: 100%;
  margin-bottom: 10px;

  @media only screen and (min-width: 576px) {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 10px;
  }
`