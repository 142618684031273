import * as React from "react"
import { Col, Row } from "react-flexbox-grid"

import { AppStateContext } from "../../utils/PageWrapper"
import { _processLink } from "../shared/links.styles"
import { CtaButton } from "./CtaButton"
import {
    _caseContent,
    _caseTitle,
    _contentContainer,
    _index,
    _indexPosition,
    _item,
    _itemContent,
    _itemPosition,
    _list,
    _nextSlideButton,
    _separator,
    _title,
    _wrapper
} from "./main/CaseSection.styles"
import { _talentIdImage } from "./TalentId.styles"

interface TalentIdProps {
    caseTitle: string
    title: string | JSX.Element
    lead: string | JSX.Element
    image: string
    ctaLabel: string
    ctaLink: string
}

export const TalentId: React.FC<TalentIdProps> = ({ title, caseTitle, lead, image, ctaLabel, ctaLink }) => {
    const appState = React.useContext(AppStateContext)
    if (!appState.isReady) return null

    return (
        <Row middle="xs">
            {!appState.isMobile && (
                <Col lg={7} md={8} sm={12}>
                    <_contentContainer>
                        <_talentIdImage src={image} />
                    </_contentContainer>
                </Col>
            )}

            <Col lg={5} md={8} sm={12}>
                <_contentContainer>
                    <_caseTitle>{caseTitle}</_caseTitle>
                    <_title align="left" marginBottom={0.8}>
                        {title}
                    </_title>
                    {appState.isMobile && <_talentIdImage src={image} />}
                    <_caseContent fontSize={16} marginTop={0.8}>
                        {lead}
                    </_caseContent>
                    <CtaButton label={ctaLabel} link={ctaLink} />
                </_contentContainer>
            </Col>
        </Row>
    )
}
