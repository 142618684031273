import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h3, _h2ParagraphNoMargin } from "../shared/headline.styles"
import { Row, Col } from "react-flexbox-grid"
import { _customMarginRow } from "../shared/row.styles"
import { _uppercaseLink } from "../shared/links.styles"
import { _cardDescription } from "../../components/shared/cards/card.styles"
import { _linkButton } from "../../components/shared/buttons"

import targetedInsightsSvg from "../../images/homepage/targeted-insights.svg"
import {
    _targetInsightsGraphWrapper,
    _radarWrapper,
    _content,
    _graphTitle,
    _domainInsightsWrapper,
    _rangerWrapper,
    _debriefWrapper,
    _img
} from "./targetInsights.styles"

interface TargetInsightsProps {
    title: string | JSX.Element
    leads: JSX.Element | string
    mobileImage: string
    radar: {
        title: string | JSX.Element
        subtitle: string | JSX.Element
        description: string | JSX.Element
    }
    domain: {
        title: string | JSX.Element
        subtitle: string | JSX.Element
        description: string | JSX.Element
    }
    ranger: {
        title: string | JSX.Element
        subtitle: string | JSX.Element
        description: string | JSX.Element
    }
    debrief: {
        title: string | JSX.Element
        subtitle: string | JSX.Element
        description: string | JSX.Element
    }
}

export const TargetInsights: React.FC<TargetInsightsProps> = ({
    title,
    leads,
    mobileImage,
    radar,
    domain,
    ranger,
    debrief
}) => {
    const appState = React.useContext(AppStateContext)
    if (!appState.isReady) return null

    return (
        <>
            <_customMarginRow marginTop={0.5}>
                <Col xs={12}>
                    <Row center="xs">
                        <Col xl={8} lg={8} sm={12}>
                            <_h2ParagraphNoMargin fontSizeSm={36} marginBottom={0.5}>
                                {title}
                            </_h2ParagraphNoMargin>
                            <_content
                                align="center"
                                maxWidth="570px"
                                marginTop={0.5}
                                marginLeft="auto"
                                marginRight="auto">
                                {leads}
                            </_content>
                        </Col>
                    </Row>
                </Col>

                {appState.isMobile ? (
                    <Col xs={12}>
                        <img src={mobileImage} style={{ maxWidth: "100%" }} />
                    </Col>
                ) : (
                    <Col xs={12}>
                        <Row center="xs">
                            <Col xl={12} lg={12} sm={12}>
                                <_targetInsightsGraphWrapper>
                                    <_radarWrapper>
                                        <_content color="#000" marginTop={1}>
                                            <_graphTitle>
                                                <strong>{radar.title}</strong>
                                                {radar.subtitle}
                                            </_graphTitle>
                                            <_content color="#646c7a">{radar.description}</_content>
                                        </_content>
                                    </_radarWrapper>
                                    <_domainInsightsWrapper>
                                        <_content color="#000" marginTop={1}>
                                            <_graphTitle maxWidth={"255px"}>
                                                <strong>{domain.title}</strong>
                                                {domain.subtitle}
                                            </_graphTitle>
                                            <_content color="#646c7a">{domain.description}</_content>
                                        </_content>
                                    </_domainInsightsWrapper>
                                    <_rangerWrapper>
                                        <_content color="#000" marginTop={1}>
                                            <_graphTitle maxWidth={"255px"}>
                                                <strong>{ranger.title}</strong>
                                                {ranger.subtitle}
                                            </_graphTitle>
                                            <_content color="#646c7a">{ranger.description}</_content>
                                        </_content>
                                    </_rangerWrapper>
                                    <_img src={targetedInsightsSvg} />
                                    <_debriefWrapper>
                                        <_content color="#000" marginTop={1}>
                                            <_graphTitle maxWidth={"255px"}>
                                                <strong>{debrief.title}</strong>
                                                {debrief.subtitle}
                                            </_graphTitle>
                                            <_content color="#646c7a">{debrief.description}</_content>
                                        </_content>
                                    </_debriefWrapper>
                                </_targetInsightsGraphWrapper>
                            </Col>
                        </Row>
                    </Col>
                )}
            </_customMarginRow>
        </>
    )
}
