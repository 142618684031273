import * as React from "react"

import { Col, Row } from "react-flexbox-grid"
import Slider from "react-slick"

import { AppStateContext } from "../../../utils/PageWrapper"
import { SliderSettings } from "../../shared"
import { _processLink } from "../../shared/links.styles"
import CheckIcon from "../../../images/v2/check-icon.svg"
import { CtaButton } from "../CtaButton"
import {
    CaseSectionSliderItem,
    ShadowedImage,
    SliderContainer,
    _caseContent,
    _caseTitle,
    _contentContainer,
    _index,
    _indexPosition,
    _item,
    _itemContent,
    _itemPosition,
    _list,
    _nextSlideButton,
    _separator,
    _title,
    _wrapper
} from "./CaseSection.styles"
import NextSlideButton from "../../../images/homepage/arrow-left-slider.svg"

interface ChecklistSectionProps {
    caseTitle: string
    title: string | JSX.Element
    lead: string | JSX.Element
    list: string[]
    image: string
    ctaLabel: string
    ctaLink?: string
    imagePosition?: string
}

const contentSection = ({
    caseTitle,
    title,
    lead,
    list,
    image,
    ctaLabel,
    ctaLink
}: {
    caseTitle: string
    title: string | JSX.Element
    lead: string | JSX.Element
    list: string[]
    image: string
    ctaLabel: string
    ctaLink?: string
}): JSX.Element => {
    const appState = React.useContext(AppStateContext)
    if (!appState.isReady) return null

    return (
        <_contentContainer>
            <_caseTitle>{caseTitle}</_caseTitle>
            <_title align="left" marginBottom={0.8}>
                {title}
            </_title>
            {appState.isMobile && imageSection(image)}
            <_caseContent fontSize={16} marginTop={0.8}>
                {lead}
            </_caseContent>
            {list.length > 0 && (
                <Row>
                    <Col md={12} sm={12}>
                        <_list>
                            {list.map((element, index) => (
                                <_item key={index}>
                                    <img src={CheckIcon} />
                                    <_itemContent>{element}</_itemContent>
                                </_item>
                            ))}
                        </_list>
                    </Col>
                </Row>
            )}
            <CtaButton label={ctaLabel} link={ctaLink} caseTitle={caseTitle} />
        </_contentContainer>
    )
}

const imageSection = (items: any): JSX.Element => {
    const slider = React.useRef<Slider>(null)
    const getIndex = (index: number) => `0${index + 1}`
    const scrollToNextSlide = () => {
        if (slider && slider.current) {
            slider.current.slickNext()
        }
    }

    return (
        <SliderContainer>
            <Slider ref={slider} {...SliderSettings({ slidesToShow: 1, arrows: false, dots: false })}>
                {items.map((item: any, index: number) => {
                    return (
                        <CaseSectionSliderItem key={`img-${index}`}>
                            <ShadowedImage>
                                <img src={item.url} />
                            </ShadowedImage>
                            <div>
                                <_itemPosition>
                                    <div>
                                        <_indexPosition className="current">{getIndex(index)}</_indexPosition>
                                        <_separator />
                                        <_indexPosition>{getIndex(items.length - 1)}</_indexPosition>
                                    </div>
                                    <_nextSlideButton onClick={scrollToNextSlide}>
                                        <img src={NextSlideButton} />
                                    </_nextSlideButton>
                                </_itemPosition>
                            </div>
                        </CaseSectionSliderItem>
                    )
                })}
            </Slider>
        </SliderContainer>
    )
}

export const CaseSection: React.FC<ChecklistSectionProps> = ({
    title,
    caseTitle,
    lead,
    list,
    image,
    imagePosition,
    ctaLabel,
    ctaLink
}) => {
    const appState = React.useContext(AppStateContext)
    if (!appState.isReady) return null

    const isRight = imagePosition === "right"

    if (appState.isMobile) {
        return (
            <Row middle="xs">
                <Col sm={12}>{contentSection({ title, lead, list, caseTitle, ctaLabel, ctaLink, image })}</Col>
            </Row>
        )
    }

    const leftSide = isRight
        ? contentSection({ title, lead, list, caseTitle, ctaLabel, ctaLink, image })
        : imageSection(image)
    const rightSide = isRight
        ? imageSection(image)
        : contentSection({ title, lead, list, caseTitle, ctaLabel, ctaLink, image })

    const content = isRight ? (
        <Row middle="xs">
            <Col lg={5} md={10} sm={12}>
                {leftSide}
            </Col>

            <Col lg={7} md={10} sm={12}>
                {rightSide}
            </Col>
        </Row>
    ) : (
        <Row middle="xs">
            <Col lg={7} md={10} sm={12}>
                {leftSide}
            </Col>

            <Col lg={5} md={10} sm={12}>
                {rightSide}
            </Col>
        </Row>
    )

    return content
}
