import * as React from "react"

import { _h2, _h3 } from "../../../../shared/headline.styles"
import { _Blockquote } from "../../../../shared/blockquote.styles"
import { _customMarginRow } from "../../../../shared/row.styles"
import { em } from "../../../../../styles"
import { _buttonBlock } from "../../../../shared/buttons"
import { _processExternalLink } from "../../../../shared/links.styles"
import { CheckboxFormikField, Input } from "../../../../shared/inputs"

import { Formik } from "formik"
import * as yup from "yup"
import { onSubmit } from "./Submit"
import styled from "styled-components"

const _linkList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 2em;
`

const newsletterInitialValues = {
    email: "",
    newsletterSubscribe: ["newsletterSubscribe"]
}

const newsletterValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email("E-mail is not valid!")
        .required("E-mail is required!")
})

interface NewsletterFormProps {
    isSubmitting: boolean
    errors: any
    handleChange: any
    handleSubmit: any
    submitCount: number
    values: any
    status?: string
    debriefPdfLinks: any
}

const NewsletterForm = (props: NewsletterFormProps) => {
    const { isSubmitting, errors, handleChange, handleSubmit, values, status, debriefPdfLinks } = props

    const successMessage = (isSubscription: boolean) => {
        return (
            <>
                <_h2 align={"left"} fontSize={24} margin={"0"}>
                    {isSubscription && "Thanks for subscription! "}
                    Here are your links:
                </_h2>

                <_linkList>
                    {debriefPdfLinks.map((link: { url: string; name: string }, index: number) => {
                        return (
                            <li key={index}>
                                <_processExternalLink target="_blank" href={link.url}>
                                    {link.name}
                                </_processExternalLink>
                            </li>
                        )
                    })}
                </_linkList>
            </>
        )
    }

    if (status === "success") return successMessage(values.newsletterSubscribe.length !== 0)

    return (
        <>
            <_h2 align={"left"} fontSize={24} margin={"0"}>
                Leave your email to get the report
            </_h2>

            <form>
                <Input
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="name@mail.com"
                    onChange={handleChange}
                    errors={errors.email}
                    value={values.email}
                />

                <CheckboxFormikField
                    name="newsletterSubscribe"
                    value="newsletterSubscribe"
                    label="Subscribe to the newsletter"
                    errors={errors.newsletterSubscribe}
                />

                <_buttonBlock onClick={handleSubmit} type="submit" style={{ marginTop: em(1) }}>
                    {isSubmitting ? "Loading..." : "Submit"}
                </_buttonBlock>
            </form>
        </>
    )
}

export const FormContainer = (debriefPdfLinks: any) => {
    return (
        <Formik
            render={formikProps => <NewsletterForm {...formikProps} {...debriefPdfLinks} />}
            validationSchema={newsletterValidationSchema}
            initialValues={newsletterInitialValues}
            onSubmit={onSubmit}
        />
    )
}

export const HubspotFormContainer = () => {
    React.useEffect(() => {
        if ((window as any).hbspt) {
            ;(window as any).hbspt.forms.create({
                portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
                formId: process.env.GATSBY_HUBSPOT_RANGER_NEWSLETTER_FORM_ID,
                target: "#hubspot-form"
            })
        }
    }, [])

    return <div id="hubspot-form" />
}
