import styled from "styled-components"
import { _h3 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"

interface TitleProps {
  maxWidth?: number | string
}

export const _targetInsightsGraphWrapper = styled.div`
  position: relative;
  transform: translate(-82px, 0px);
  padding-bottom: 110px;

  @media only screen and (min-width: 992px) {
    padding-bottom: 180px;

  }
`

export const _radarWrapper = styled.div`
  position: absolute;
  max-width: 345px;
  top: 0;

  @media only screen and (min-width: 576px) {
    left: 350px;
  }

  @media only screen and (min-width: 992px) {
    left: 465px;
  }

  @media only screen and (min-width: 1200px) {
    left: 565px;
  }
`

export const _graphTitle = styled.p<TitleProps>`
  margin: 0 0 20px;
  font-size: 18px;
  color: #000;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};

  @media only screen and (min-width: 576px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }


  > strong {
    text-transform: uppercase;
    display: block;
  }

  p {
    margin: 0;
    color: #000;
  }
`

export const _domainInsightsWrapper = styled.div`
  position: absolute;
  max-width: 345px;
  top: 400px;

  @media only screen and (min-width: 576px) {
    left: 125px;
    top: 310px;
  }

  @media only screen and (min-width: 992px) {
    top: 400px;
    left: 178px;
  }

  @media only screen and (min-width: 1200px) {
    top: 400px;
    left: 282px;
  }
`

export const _rangerWrapper = styled.div`
  position: absolute;
  top: 400px;
  width: 100%;
  max-width: 345px;

  @media only screen and (min-width: 576px) {
    left: 663px;
    top: 310px;
    max-width: 180px;
  }

  @media only screen and (min-width: 992px) {
    top: 400px;
    left: 870px;
    max-width: 185px;
  }

  @media only screen and (min-width: 1200px) {
    left: 974px;
    max-width: 345px;
  }

  @media only screen and (max-width: 1335px) and (min-width: 1201px) {
    max-width: 260px;
  }
`

export const _debriefWrapper = styled.div`
  position: absolute;
  top: 690px;
  max-width: 345px;

  @media only screen and (min-width: 576px) {
    top: 530px;
    left: 495px;
  }

  @media only screen and (min-width: 992px) {
    top: 690px;
    left: 646px;
    max-width: 215px;
  }

  @media only screen and (min-width: 1200px) {
    top: 690px;
    left: 750px;
  }
`

export const _content = styled(_Blockquote)`
  @media only screen and (min-width: 576px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }
`

export const _img = styled.img`
  @media only screen and (min-width: 575px) {
    max-width: 100%;
  }

  @media only screen and (min-width: 992px) {
    max-width: inherit;
  }
`