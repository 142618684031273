import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Layout, _Section, HeadComponent } from "../components/shared"
import { prismicGetText, prismicGetRichText, extractPrismicNode, prismicGetRichHeader } from "../utils/prismicHelper"
import { PageWrapper } from "../utils/PageWrapper"
import { getWindowDimensions, checkIfMobile } from "../utils"

import Hexagon1 from "../images/homepage/hexagonal/1.svg"
import Hexagon2 from "../images/homepage/hexagonal/2.svg"
import Hexagon3 from "../images/homepage/hexagonal/3.svg"
import Hexagon4 from "../images/homepage/hexagonal/4.svg"

import { Platforms, TargetInsights, WhyRadicle, TalentId } from "../components/productsPage"
import { CaseSection } from "../components/productsPage/main/CaseSection"

const _svgHexagonImage = styled.img`
    position: absolute;
    top: 260px;
    left: 90%;
    z-index: -1;
`

const _svgHexagonImage1 = styled.img`
    position: absolute;
    top: 0;
    left: -44%;
    z-index: -1;
`

const _svgHexagonImage2 = styled.img`
    position: absolute;
    top: 200px;
    left: 98%;
    z-index: -1;
`

const _svgHexagonImage3 = styled.img`
    position: absolute;
    top: -260px;
    left: -51%;
    z-index: -1;
`

export const prismicQuery = graphql`
    {
        prismic {
            ...productsHomeFragment
            ...productsFragment
        }
    }
`

interface IndexPageProps {
    data: {
        prismic: {
            allHomes: {
                edges: any
            }
            allProduct: {
                edges: any
            }
        }
    }
}

const ProductsPage: React.FC<IndexPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allHomes")
    const docProducts = extractPrismicNode(data, "allProductss")
    if (!doc || !docProducts) return null

    const [isMobile, setIsMobile] = React.useState<boolean>(checkIfMobile(getWindowDimensions()))
    const [ready, setReady] = React.useState(false)

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            setReady(true)
        }

        function handleResize() {
            setIsMobile(checkIfMobile(getWindowDimensions()))
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const normalizedTimeScaleItems = doc.clients_items_v2.map((item: any) => ({
        title: prismicGetText(item.item_title_clients_v2),
        text: prismicGetRichText(item.item_description_v2),
        image: item.item_image_v2.url
    }))

    const normalizedPlatformItems = docProducts.items_platform.map((item: any) => ({
        title: prismicGetText(item.item_title_platform),
        lead: prismicGetText(item.description_platform),
        image: item.image_platform.url
    }))

    const normalizedBriefImages = docProducts.images_debrief.map((item: any) => ({
        url: item.image_debrief.url
    }))

    const normalizedDomainInsightsImages = docProducts.images_domain_insights.map((item: any) => ({
        url: item.image_domain_insights.url
    }))

    const normalizedRadarImages = docProducts.images.map((item: any) => ({
        url: item.radar_image.url
    }))

    const normalizedRangerImages = docProducts.images_ranger.map((item: any) => ({
        url: item.image_ranger.url
    }))

    const debriefList = docProducts.items_debrief.map((question: { question_debrief: Array<{ text: any}> }) => {
        return prismicGetRichText(question.question_debrief)
    })

    const normalizedDialoguesImages = docProducts.images_dialogues.map((item: any) => ({
        url: item.image_dialogues.url
    }))

    return (
        <>
            <HeadComponent
                title="Radicle"
                keywords={prismicGetText(doc.seo_keywords_home)}
                description={prismicGetText(doc.seo_description_home)}
            />
            <PageWrapper>
                <Layout>
                    {!isMobile && ready && <_svgHexagonImage src={Hexagon1} />}
                    <_Section marginTop={0.5} marginBottom={0.5}>
                        <WhyRadicle
                            title={prismicGetRichHeader(doc.title_clients_v2)}
                            leads={prismicGetText(doc.lead_clients)}
                            items={normalizedTimeScaleItems}
                        />
                    </_Section>

                    <_Section marginTop={0.5} marginBottom={0.5}>
                        {!isMobile && ready && <_svgHexagonImage1 src={Hexagon2} />}
                        <Platforms
                            title={prismicGetRichHeader(docProducts.title_platform)}
                            leads={prismicGetText(docProducts.lead_platfrom)}
                            items={normalizedPlatformItems}
                        />
                    </_Section>

                    <_Section marginTop={0.5} marginBottom={0.5}>
                        <TargetInsights
                            title={prismicGetRichHeader(docProducts.title_insights)}
                            leads={prismicGetText(docProducts.lead_insights)}
                            mobileImage={docProducts.insights_image.url}
                            radar={{
                                title: prismicGetRichHeader(docProducts.radar_title),
                                subtitle: prismicGetRichHeader(docProducts.radar_subtitle),
                                description: prismicGetRichHeader(docProducts.radar_description)
                            }}
                            domain={{
                                title: prismicGetRichHeader(docProducts.domain_title),
                                subtitle: prismicGetRichHeader(docProducts.domain_subtitle),
                                description: prismicGetRichHeader(docProducts.domain_description)
                            }}
                            ranger={{
                                title: prismicGetRichHeader(docProducts.ranger_title),
                                subtitle: prismicGetRichHeader(docProducts.ranger_subtitle),
                                description: prismicGetRichHeader(docProducts.ranger_description)
                            }}
                            debrief={{
                                title: prismicGetRichHeader(docProducts.debrief_title),
                                subtitle: prismicGetRichHeader(docProducts.debrief_subtitle),
                                description: prismicGetRichHeader(docProducts.debrief_description)
                            }}
                        />
                    </_Section>

                    <_Section marginTop={0.5} marginBottom={0.5}>
                        <CaseSection
                            title={prismicGetRichHeader(docProducts.title_debrief)}
                            lead={prismicGetText(docProducts.lead_debrief)}
                            ctaLabel={prismicGetText(docProducts.cta_label_debrief)}
                            list={debriefList}
                            caseTitle="Debrief"
                            image={normalizedBriefImages}
                            imagePosition="right"
                        />
                    </_Section>

                    <_Section marginTop={0.5} marginBottom={0.5}>
                        {!isMobile && ready && <_svgHexagonImage2 src={Hexagon3} />}
                        <CaseSection
                            title={prismicGetRichHeader(docProducts.title_radar)}
                            lead={prismicGetRichText(docProducts.lead_radar)}
                            ctaLabel={prismicGetText(docProducts.cta_label_radar)}
                            list={[]}
                            caseTitle="Radar"
                            image={normalizedRadarImages}
                            ctaLink={docProducts.link_to_sample_radar?.url}
                            imagePosition="left"
                        />
                    </_Section>

                    <_Section marginTop={0.5} marginBottom={0.5}>
                        <CaseSection
                            title={prismicGetRichHeader(docProducts.title_domain_insights)}
                            lead={prismicGetRichText(docProducts.lead_domain_insights)}
                            ctaLabel={prismicGetText(docProducts.cta_label_domain_insights)}
                            list={[]}
                            caseTitle="Domain insights"
                            image={normalizedDomainInsightsImages}
                            imagePosition="right"                            
                        />
                    </_Section>

                    <_Section marginTop={0.5} marginBottom={0.5}>
                        <CaseSection
                            title={prismicGetRichHeader(docProducts.title_ranger)}
                            lead={prismicGetRichText(docProducts.lead_ranger)}
                            ctaLabel={prismicGetText(docProducts.cta_label_ranger)}
                            list={[]}
                            caseTitle="Ranger"
                            image={normalizedRangerImages}
                            imagePosition="left"
                        />
                    </_Section>

                    <_Section marginTop={0.5} marginBottom={0.5}>
                        {!isMobile && ready && <_svgHexagonImage3 src={Hexagon4} />}
                        <CaseSection
                            title={prismicGetRichHeader(docProducts.title_dialogues)}
                            lead={prismicGetRichText(docProducts.lead_dialogues)}
                            ctaLabel={prismicGetText(docProducts.cta_label_dialogues)}
                            ctaLink={'/contact'}
                            list={[]}
                            caseTitle="Dialogues"
                            image={normalizedDialoguesImages}
                            imagePosition="right"
                        />
                    </_Section>

                    <_Section>
                        <TalentId
                            title={prismicGetRichHeader(docProducts.title_talent)}
                            lead={prismicGetRichText(docProducts.lead_talent)}
                            ctaLabel={prismicGetText(docProducts.cta_label_talent)}
                            image={docProducts.image_talent.url}
                            ctaLink={'/contact'}
                            caseTitle="Talent ID"
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default ProductsPage
